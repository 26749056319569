/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* Style for the radio label */
.radio-label {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

/* Styling for the active radio button */
.active-radio input[type="radio"] {
  accent-color: #FF4D67;
  margin-right: 10px;
  /* Change the color of the selected radio to pink */
}

/* Adjust spacing between the radio button and the label text */
.radio-label span {
  margin-left: 30px;
  /* Adds space between radio and the text */
}

/* Custom style for the radio button itself */
.custom-radio {
  width: 18px;
  height: 18px;
  margin-right: 10px;

}

/* Ensure vertical alignment of the radio buttons */
.radio-label {
  display: flex;
  align-items: center;
}