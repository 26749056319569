@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&family=Roboto+Slab:wght@400;500;600;700&display=swap");
/* font-family: 'Poppins', sans-serif;
font-family: 'Roboto Slab', serif; */

:root {
  --black--: #000;
  --white--: #fff;
  --prime--: #524ae3;
  --blackPrime--: #0f172a;
  --pink--: #cb198e;
  --blackLight--: #1920325c;
  --grayPrime--: #404040;
  --inputBorder--: #cbd5e1;
  --btnPrime--: #524ae3;
  --tablePrime--: #f8fafc;
  --tablePrime--: #e2e8f0;
  --scrollColor--: #b6b6b6;
  --headerPrime--: #f0f4f8;
  --fontPoppins--: "Poppins", sans-serif;
  --fontRoboto--: "Roboto Slab", serif;
  --TableColumnBold--: #334155;
  --TableFont--: #404040;
  --lightOrangeColor: #ff4d67;
  --textLightColor: #565656;
}

/* ======================== Start Input CSS ======================== */

/* all Fields css */
.mobSidebar .mainMenu li a i {
  margin-left: -5px !important;
}

.prime-input {
  margin-bottom: 10px;
  position: relative;
}

.form-input {
  width: 100% !important;
  height: 43px !important;
  border: 1px solid var(--inputBorder--) !important;
  padding: 16px;
  color: #404040 !important;
  font-size: 14px;
  cursor: pointer;
  background-color: #dee2e694;
}

.prime-input label {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  color: var(--blackPrime--);
}

.subMenu i {
  display: flex;
  align-items: center;
}

.mainMenu .toggleIcon {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

/* For Input Type Password */

.passHideShow {
  position: absolute;
  top: 40px;
  right: 12px;
  cursor: pointer;
}

/* For Input Type Search */

.searching {
  position: absolute;
  top: 14px;
  right: 12px;
  cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  font-size: 16px;
}

/* For Input Type Number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Type Radio And Check Box */
.form-check {
  position: relative;
  display: block;
  /* padding-left: 4.25rem; */
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-label {
  margin-bottom: 0;
}

.radioWidth {
  height: 280px !important;
}

.prime-input.radio,
.prime-input.checkbox {
  display: flex;
  align-items: center;
}

.prime-input.radio label,
.prime-input.checkbox label {
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
}

.form-input[type="radio"],
.form-input[type="checkbox"] {
  height: 18px !important;
  width: 18px !important;
}

.form-input[type="radio"],
.form-input[type="checkbox"] {
  accent-color: var(--blackPrime--);
}

/* For Input Type File */

.form-input[type="file"]::file-selector-button {
  margin-right: 10px;
  border: none;
  background: var(--blackPrime--);
  padding: 0px 20px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  height: 43px !important;
}

.form-input[type="file"] {
  padding: 0px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.errorMessage {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: red !important;
}

@media screen and (max-width: 425px) {
  .form-input[type="file"]::file-selector-button {
    margin-right: 10px;
    border: none;
    background: var(--blackPrime--);
    padding: 0 5px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    height: 35px;
  }
}

/* ======================== End Input CSS ======================== */

/* ======================== Start Button CSS ======================== */

.themeBtn {
  border: 0;
  padding: 10px;
}

.bgsuccess {
  background-color: rgb(25, 135, 84);
}

.searchBarBorder {
  border-right: none !important;
}

.searchBarBorder:focus {
  border-right: none !important;
  border: 1px solid rgb(166, 163, 163) !important;
}

/* Button Background Color CSS */

.btnPrime {
  background-color: var(--btnPrime--) !important;
  color: var(--white--) !important;
}

.btnBlackPrime {
  background-color: var(--blackPrime--) !important;
  color: var(--white--) !important;
}

/* ======================== End Button CSS ======================== */

/* ======================== Start Toggle Switch CSS ======================== */

.checkbox {
  display: none;
}

.slider {
  width: 60px;
  height: 30px;
  background-color: lightgray;
  border-radius: 20px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 4px solid transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
  box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
  cursor: pointer;
}

.Mui-checked {
  color: var(--lightOrangeColor) !important;
}

.Mui-checked+.MuiSwitch-track {
  background-color: #d04a5e !important;
}

/* ======================== End Toggle Switch CSS ======================== */
/* ======================== Start Table CSS ======================== */

.primeHeader {
  /* border-bottom: 1px solid var(--grayPrime--); */
  padding: 0px 30px;
  /* background-color: var(--headerPrime--); */
}

.titlePath {
  font-weight: 500;
}

/* tost */
.Toastify__toast-body div {
  text-transform: capitalize !important;
}

/* tost */
/* model */
.MuiModal-root {
  z-index: 9999999 !important;
}

/* model */
.swal-overlay {
  z-index: 99999999;
}

.primeMain {
  /* min-height: 75vh;
  max-height: 75vh; */
  /* overflow: auto; */
  /* margin-top: 10px; */
  font-size: 14px;
  /* border-radius: 15px; */
  /* padding: 0 20px; */
  font-family: var(--fontPoppins--);
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableMain {
  /* background-color: white; */
  border-radius: 16px !important;
  /* width: fit-content; */
  /* overflow: overlay;
  margin: 0px 18px; */

  overflow: scroll;
  margin: 0px 18px;
  max-height: 660px;
}

/* .primeTable {} */

.primeTable tr th {
  background-color: inherit;
  padding: 12px 16px;
  font-family: var(--fontPoppins--);
  font-size: 14px;
  font-weight: 600;
}

.primeTable {
  background-color: #fcfcfc;
}

.primeTable tr th:nth-child(1) {
  border-radius: 22px 22px 0px 0px;
  border-top-right-radius: 0px;
}

.primeTable tr th:nth-last-child(1) {
  border-radius: 22px 22px 0px 0px !important;
  border-top-left-radius: 0px !important;
}

/* .primeTable tr td {
  padding: 12px 20px;
  border-bottom: 1px solid #cfcfcf;
  font-family: "Poppins", sans-serif;
  color: var(--TableFont--);
  font-size: 15px;
  font-weight: 400;
} */
tbody tr td {
  padding: 8px 10px;
  /* border-bottom: 1px solid #cfcfcf; */
  font-family: "Poppins", sans-serif;
  color: var(--TableFont--);
  /* font-size: 15px; */
  font-weight: 400;
  background-color: white;
}

thead tr td {
  padding: 12px 20px;
  border-bottom: 1px solid #cfcfcf;
  font-family: "Poppins", sans-serif;
  color: var(--TableFont--);
  /* font-size: 15px; */
  font-weight: 400;
}

thead {
  background-color: #e2e8f0;
}

.tableBoldFont {
  color: var(--TableColumnBold--);
  /* font-size: 14px;  */
  font-weight: 500;
}

/* pagination CSS */

.paginationBtn:disabled {
  opacity: 0.8;
}

/* ================================================Pagination CSS=========================================== */
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.pageOption {
  padding: 5px 6px;
  border-radius: 10%;
}

.count {
  margin: 15px;
}

.page-btn {
  border-radius: 65px;
  border: 1px solid #c9c9c9;
  background: #fff;
  width: 45px;
  margin-top: 15px;
  height: 45px;
  margin-right: 8px;
}

.page-btn svg {
  width: 16px;
  height: 18px;
}

.active {
  height: 45px;
  width: 45px;
  /* padding: 10px 17px; */
  border-radius: 50px;
  color: #cfcfcf;
  border: none;
  margin-top: 15px;
}

.active svg path {
  color: white;
}

.active-btn {
  height: 50px;
  width: 50px;
  margin: 5px;
  /* padding: 10px 15px; */
  border-radius: 50%;
  /* background-color: #494594; */
  color: #000;
  border: none;
}

.pagination .makeStyles-root-29 {
  display: flex !important;
}

/* Pagination Selection */

.tableSelection {
  margin-right: 15px;
  padding: 5px;
}

/* Pagination Selection */

.tableSelection {
  margin-right: 15px;
  padding: 5px;
}

/* Dialogue CSS */

.mainDialogue {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #00000050;
  opacity: 0;
  animation: fade-in 0.1s ease-in;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Dialogue {
  width: 600px;
  min-height: 290px;
  background-color: var(--white--);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 111;
  border-radius: 10px;
}

.faeHostDialog {
  max-width: 65%;
  min-height: 290px;
  background-color: var(--white--);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 111;
  border-radius: 10px;
  max-height: 90vh;
}

@media screen and (max-width: 630px) {
  .Dialogue {
    width: 97%;
  }
}

/* Header */
.dialogueHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: 20px;
}

.headerTitle {
  font-size: 22px;
}

.closeBtn {
  background-color: var(--blackPrime--);
  color: var(--white--);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
}

/* Main */
.dialogueMain {
  padding: 20px;
  position: relative;
}

/* Footer */
.dialogueFooter {
  padding: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
}

@media screen and (max-width: 426px) {
  .dialogueFooter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* ======================== End Table CSS ======================== */

/* ======================== Start Scroll CSS ======================== */
body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

/* width */

/* ======================== End Scroll CSS ======================== */

/* ======================== Start Setting Box CSS ======================== */
.dayAnalytics {
  display: flex;
  justify-content: flex-end;
}

.date-range-box {
  position: relative;
  display: flex;
  align-items: center;
}

.date-range-box input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.date-range-box input:focus-visible {
  outline: none;
}

.right-drp-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 92px;
  color: white;
  height: 41px;
  border-radius: 0px 100px 100px 0px;
  background: #ff4d67;
  font-size: 14px;
}

.right-drp-btn svg {
  color: white;
}

.settingMain {
  padding: 20px;
  overflow: auto;
  /* height: 82vh; */
}

.settingBox {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  /* box-shadow: 0 0 4px #0000005c; */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.loginType {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  /* box-shadow: 0 0 4px #0000005c; */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.bgSetting {
  background-color: #fff;
}

.settingInnerBox .prime-input {
  margin-bottom: 5px !important;
}

.settingBoxHeader {
  margin-bottom: 15px;
}

.mainTitle {
  font-size: 22px;
  font-weight: bold;
}

/* ======================== End Setting Box CSS ======================== */

/* ======================== Start Dashboard Box CSS ======================== */
.mainDashboard {
  padding: 30px;
}

.dashboardBox {
  padding: 15px;
  background-color: #ededed;
  border-radius: 6px;
  box-shadow: -2px 2px 8px #00000030;
}

.dashIcon {
  font-size: 40px;
}

.dName {
  font-size: 20px;
}

.dCount {
  font-size: 40px;
  line-height: 40px;
}

.showMoreBtn {
  margin-top: 10px;
}

/* extra */

.description {
  width: calc(100% - 160px) !important;
}

#description {
  width: calc(100% - 160px) !important;
}

.cursor {
  cursor: pointer !important;
}

.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.loader2 {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ======================== End Dashboard Box CSS ======================== */

.totalDollar {
  border: 1px solid darkgray;
  padding: 12px;
  border-radius: 0 10px 10px 0px;
  border-left: none;
}

.totalTex {
  /* border: 1px solid; */
  padding: 13px;
  border-radius: 10px 0 0 10px !important;
  background: var(--bs-green);
  color: white;
  border-radius: 10px;
}

.userBtn {
  border-radius: 5px;
  margin: auto;
  width: 40px;
}

/* ======================== IMPORT IN STYLE CSS ======================== */
/* Table CSS */
/* .userMain table{
  position: relative;
}
.userMain .pagination {
  position: sticky;
  bottom: 0;
  height: 82px;
  background-color: #f1f5f9;
  width: 100%;
  margin: 0px;
} */
.userMain thead {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 45px;
}

/* fake host dialogue */
.setImage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 190px;
  /* overflow-y: auto; */
}

.img-container {
  position: relative;
}

.img-container i {
  position: absolute;
  right: 18px;
  top: 4px;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.scroll-content {
  display: flex;
  align-items: center;
  height: 100%;
  /* overflow-y: auto  ; */
}

::-webkit-scrollbar {
  width: 2px;
  color: #404040;
}

.switchTitleColor {
  color: #64748b;
}

.p2 {
  padding: 12px;
}

/* meTube */

.searchBtn {
  border-radius: 62px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 56px;
  height: 43px;
}

.search-input-box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.searching-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.searching-box svg {
  width: 22px;
  height: 22px;
}

.searching-box input {
  height: 56px;
  border: 1px solid var(--lightOrangeColor) !important;
  background-color: white !important;
}

.searching-box input {
  outline: none;
}

.searching-box button {
  background-color: var(--lightOrangeColor);
}

.sideBarLogo {
  background-color: #f8f8f8;
}

.sideBar {
  border-right: 2px solid #ececec;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

/* dayAnalytics */
.dayAnalytics .MuiInputBase-root {
  border-radius: 64px !important;
}

.dayAnalytics .MuiFormControl-root .Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}

.dayAnalytics #dayAnalytics-select {
  display: flex;
  justify-content: center;
}

.dayAnalytics .MuiOutlinedInput-notchedOutline {
  border-color: var(--lightOrangeColor);
}

/* .dayAnalytics svg{
  background-color: var(--lightOrangeColor);
  color: white;
  position: absolute;
  top: 0;
  right: 0;
    width: 50px;
    height: 100%;
    border-radius: 68px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
 } */
.dayAnalytics .MuiSelect-iconOpen {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 68px;
}

.dayAnalytics .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff9eac !important;
}

.dayAnalytics .MuiInputBase-root:hover {
  border-color: #ff9eac !important;
}

.dayAnalytics .MuiInputBase-root {
  height: 43px;
  width: 190px;
}

.dayAnalytics .MuiBox-root {
  display: flex;
  justify-content: flex-end;
}

.dayAnalytics .Mui-focused .MuiOutlinedInput-notchedOutline {
  color: rgba(0, 0, 0, 0.54) !important;
}

/* dayAnalytics */

/* Multi Button */
.multiButton .MuiButtonBase-root {
  border: none !important;
}

.multiButton .MuiToggleButtonGroup-root {
  border: 1px solid var(--lightOrangeColor);
  border-radius: 28px;
}

.multiButton button {
  border-radius: 28px;
  min-width: 87px;
  width: 100%;
  margin: 2px;
  padding: 9px 11px;
  font-size: 15px;
  color: #404040;
}

.multiButton .MuiToggleButtonGroup-root:nth-child(1) {
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.multiButton .MuiToggleButtonGroup-root:nth-child(2) {
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}

.multiButton .MuiToggleButtonGroup-root:nth-child(3) {
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}

.multiButton .MuiToggleButtonGroup-root:nth-child(4) {
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}

.multiButton .MuiToggleButtonGroup-root:nth-child(5) {
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}

.multiButton .MuiToggleButtonGroup-root:nth-last-child(1) {
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  border-right: 1px solid var(--lightOrangeColor);
}

.multiButton .Mui-selected {
  background-color: var(--lightOrangeColor) !important;
  color: white !important;
}

/* Multi Button */
/* Table */
.table-custom {
  display: block;
  max-height: 417px;
  min-height: 417px;
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: scroll;
  border: none;
  border-radius: 22px;
}

.table-custom tbody tr td:nth-child(1) {
  border-left: none !important;
}

.table-custom .table-head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-custom::-webkit-scrollbar {
  height: 2px;
  display: none;
  width: 3px;
  background-color: transparent;
}

.table-custom::-webkit-scrollbar-thumb:hover {
  transition: all 5s ease-in-out;
  background: var(--scrollColor--);
}

.table-custom::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: transparent;
}

.table-custom::-webkit-scrollbar-thumb {
  background: var(--scrollColor--);
  border-radius: 5px;
}

.table-custom thead {
  background-color: var(--lightOrangeColor);
  color: white;
  border-radius: 22px 22px 0px 0px !important;
}

.table-custom tr {
  background-color: var(--lightOrangeColor);
  color: white;
  border-radius: 22px 22px 0px 0px !important;
}

.table-custom thead tr th input {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #fff;
}

.table-custom thead tr th span {
  font-weight: 500;
  font-size: 13px;
}

.table-custom {
  margin-top: 10px;
}

.table-custom tbody tr td {
  border: 1px solid #eee;
}

/* Table */
/* Submit Btn */
.submit-btn-dialogue {
  border-radius: 36px;
  max-width: 120px;
  color: white;
  width: 100%;
  background: #0fb515;
  height: 43px;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}

.submit-btn {
  border-radius: 10px;
  max-width: 120px;
  color: white;
  width: 88px;
  background: #0fb515;
  height: 38px;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

/* Submit Btn */
/* pagination */
.custom-pagination {
  background-color: #fff;
  border-radius: 22px;
}

.custom-pagination .page-btn {
  background: #fff !important;
  opacity: 0.5;
  cursor: not-allowed !important;
}

.pagination-select .selector-custom {
  margin-top: 15px;
}

/* pagination */
.custom-pagination .pagination .disabled,
.custom-pagination .pagination .disabled a {
  opacity: 0.8;
  cursor: not-allowed !important;
}

.custom-pagination .MuiFormControl-root .Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}

.custom-pagination .page-item {
  margin-left: 10px;
  margin-right: 10px;
}

.custom-pagination .page-item:nth-child(1),
.custom-pagination .page-item:nth-child(2),
.custom-pagination .page-item:nth-last-child(1),
.custom-pagination .page-item:nth-last-child(2) {
  border-radius: 65px;
  border: 1px solid #c9c9c9;
  background: #fff;
  width: 45px;
  height: 45px;
  font-size: 19px;
  display: flex;
  margin-right: 5px !important;
  margin-left: 0px !important;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.custom-pagination .page-item a {
  border-radius: 65px !important;
  background: #fff;
  width: 45px;
  height: 45px;
  align-items: center;
  font-size: 19px;
  color: rgba(33, 37, 41, 0.75) !important;
  display: flex;
  justify-content: center;
  margin-left: 0px !important;
  padding: 0px;
  border-bottom: 1px solid #c9c9c9 !important;
}

.custom-pagination .pagination .active,
.custom-pagination .pagination .active a {
  width: 45px;
  height: 45px;
  font-size: 16px;
  border-radius: 65px !important;
  background-color: var(--lightOrangeColor);
  color: white !important;
}

/* Input Custom */
.custom-input input {
  border-radius: 65px;
  border: 1px solid #c9c9c9;
  background: #fff;
}

.custom-input label {
  color: #404040;
  font-weight: 500;
  margin-bottom: 5px;
}

.custom-input .form-input:focus {
  border: 1px solid #ababab !important;
  outline: none;
  background-color: #fefdff !important;
}

/* Input Custom */
/* Selector Custom */
.selector-custom select {
  color: #495057 !important;
  font-size: 14px;
  padding: 16px;
  border: 1px solid #cbd5e1 !important;
  white-space: break-spaces;
  height: 43px;
}

.selector-custom .form-select:focus {
  box-shadow: none;
  border: 1px solid #ababab !important;
  outline: none;
  background-color: #fefdff !important;
}

/* .selector-custom select::placeholder{
  color: #858585 !important;
  font-size: 5px !important;
}
.selector-custom select:-ms-input-placeholder{
  color: #858585 !important;
  font-size: 5px !important;
}
.selector-custom option:first {
 font-size: 10px;
}

.selector-custom fieldset{
  border-radius: 65px;
  border: 1px solid var(--inputBorder--) !important;
} */

.selector-custom .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ababab !important;
  color: rgba(0, 0, 0, 0.54);
}

.selector-custom .MuiFormControl-root label {
  color: rgba(0, 0, 0, 0.54) !important;
  margin-top: -4px;
}

.selector-custom .MuiInputBase-root {
  height: 43px;
}

.selector-custom .label-selector-custom {
  color: #1f1f1f;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 15px;
  /* font-size: 15px; */
}

/* Selector Custom */
.back-btn {
  background-color: var(--lightOrangeColor);
  color: white;
  border-radius: 8px;
  width: 82px;
  height: 35px;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

/* Pagination Custom */
.pagination-select .pagination {}

/* Pagination Custom */
.create-new {
  background-color: var(--lightOrangeColor);
  max-width: 180px;
  color: white;
  font-size: 15px;
  width: 100%;
  border-radius: 64px;
}

/* react-date-range-picker */
.react-date-range-picker label {
  color: #1f1f1f;
  font-weight: 500;
  margin-bottom: 5px;
}

.react-date-range-picker input {
  border-radius: 65px;
  width: 65% !important;
  height: 43px !important;
  border: 1px solid var(--inputBorder--) !important;
  padding: 16px;
  font-size: 14px;
  cursor: pointer;
}

.react-date-range-picker input:focus {
  border: 1px solid #ababab !important;
  outline: none;
  background-color: #fefdff !important;
}

/* react-date-range-picker */
/* audio upload */
.audio-upload audio {
  width: 100% !important;
}

.audio-upload {
  display: flex;
  align-items: center;
}

/* audio upload */

.changePassword-btn {
  background-color: var(--lightOrangeColor);
  color: white;
  font-size: 15px;
  max-width: 200px;
}

.rounded {
  border-radius: 62px !important;
}